<template>
  <div v-if="ready" class="container">
    <div class="py-5 text-center">
      <img
        class="d-block mx-auto mb-4"
        :src="logo"
        alt=""
        width="20%"
        height="20%"
      />
      <h2>{{ pageContent.title }}</h2>
      <p class="lead">
        {{ pageContent.subtitle }}
      </p>
    </div>
    <b-row>
      <b-col md="8">
        <b-card header="Formas de Pagamento" header-text-variant="secondary">
          <div class="accordion" role="tablist">
            <template v-for="type in clientData.paymentType">
              <Pix v-if="type.id == '4'" :key="type.id" />
              <BankDeposit v-if="type.id == '3'" :key="type.id" />
              <BankTicket v-if="type.id == '1'" :key="type.id" />
              <CreditCard v-if="type.id == '2'" :key="type.id" />
            </template>
          </div>
        </b-card>
      </b-col>
      <b-col>
        <PanelDescription
          :items="clientData.items"
          :invoice="clientData.invoice"
        />
      </b-col>
    </b-row>
    <b-row class="mt-3" v-if="pageContent.warning">
      <b-col md="12">
        <b-card>
          <div v-html="pageContent.warning"></div>
        </b-card>
      </b-col>
    </b-row>
    <b-row class="mt-3">
      <b-col md="12">
        <b-card>
          <div v-html="pageContent.content_footer" />
        </b-card>
      </b-col>
    </b-row>

    <footer class="my-5 pt-5 text-muted text-center text-small">
      <p class="mb-1">&copy; {{ getYear }} - {{ unity.commercial_name }}</p>
      <!-- <ul class="list-inline">
        <li class="list-inline-item"><a href="#">Privacy</a></li>
        <li class="list-inline-item"><a href="#">Terms</a></li>
        <li class="list-inline-item"><a href="#">Support</a></li>
      </ul> -->
    </footer>
  </div>
</template>

<script>
export default {
  data() {
    return {
      ready: false,
    };
  },
  components: {
    Pix: () => import("./components/Pix.vue"),
    BankDeposit: () => import("./components/BankDeposit.vue"),
    BankTicket: () => import("./components/BankTicket.vue"),
    CreditCard: () => import("./components/CreditCard.vue"),
    PanelDescription: () => import("./components/PanelDescription.vue"),
  },
  computed: {
    getYear() {
      const temp = new Date();
      return new Date().getFullYear();
    },
    logo() {
      try {
        return this.unity.logo;
      } catch (error) {}
     
    },
    clientData() {
      return this.$store.state.checkout.clientData;
    },
    unity() {
      return this.clientData.unity[0];
    },
    pageContent() {
      return this.clientData.pageContent[0];
    },
  },
  async mounted() {
    console.log("called");
    const token = this.$route.params.token;
    console.log("tok", token);
    if (token) {
      await this.$store.dispatch("checkout/init", token);
    }
    this.ready = true;
  },
};
</script>
<style>
body {
  height: unset !important;
  overflow: unset !important;
}
.td {
  position: relative;
}
.bank-card {
  padding: 1rem;
  background-color: rgb(231, 231, 231);
  border-radius: 10px;
  margin: 1rem;
}
.boleto-td {
  position: relative;
}
.boleto-table {
  position: relative;
}
</style>
